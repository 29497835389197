import React from "react";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ScenariosTab from "./ScenariosTab";
import EventsTab from "./EventsTab";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(84, 94, 110, 0.4);
z-index: 1000;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  background: white;
  width: 100%;
  max-width: 1000px;
  height: 500px;
  overflow-y: auto;
  border 1px solid gray;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: -10px 0px 15px rgba(31, 41, 55, 0.2);
  font-family: "Proxima Nova Regular";
`;

const Header = styled.div`
  border-bottom: 1px solid gray;
  font-family: "Proxima Nova Semi Bold";
  padding: 20px;
  font-size: 24px;
`;

const Tabs = styled.div`
  display: flex;
  padding: 20px;
`;

const Tab = styled.div`
  border-bottom: 1px solid gray;
  margin: 0px 10px;
  padding: 0 px 10px;
  font-family: "Proxima Nova Semi Bold";
  font-size: 18px;
  cursor: pointer;
`;

const Body = styled.div`
  padding: 0 20px;
`;

const DevPanel = ({ onClose, eventBus, wiremockURI }) => {
  const [currentTab, setCurrentTab] = useState("scenarios");
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const overlayRef = useRef(null);

  // Keep all events in a log
  useEffect(() => {
    if (!eventBus) {
      return;
    }
    const handleEvent = ({ name, data }) => {
      setEvents([
        ...events,
        {
          timestamp: new Date(),
          name,
          data,
        },
      ]);
    };
    eventBus.on("*", handleEvent);
    return () => {
      eventBus.off("*", handleEvent);
    };
  }, [events, eventBus]);

  useEffect(() => {
    const handleKeydown = e => {
      if (
        e.altKey &&
        (e.key === "b" || e.key === "KeyB" || e.keyCode === 66) &&
        e.target.nodeName === "BODY"
      ) {
        e.preventDefault();
        setOpen(!open);
      }
      if (
        open &&
        (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) &&
        e.target.nodeName === "BODY"
      ) {
        e.preventDefault();
        setOpen(false);
      }
    };
    window.addEventListener("keydown", handleKeydown, true);
    return () => {
      window.removeEventListener("keydown", handleKeydown, true);
    };
  });

  if (!open) {
    return null;
  }

  const CurrentTab = () => {
    switch (currentTab) {
      case "scenarios":
        return <ScenariosTab baseURI={wiremockURI} />;
      case "events":
        return <EventsTab events={events} eventBus={eventBus} />;
      default:
        return null;
    }
  };

  const handleCloseClick = e => {
    if (overlayRef.current === e.target) {
      setOpen(false);
    }
  };
  return (
    <>
      <Overlay ref={overlayRef} onClick={handleCloseClick}>
        <Wrapper>
          <Header>Dev Pane</Header>
          <Tabs>
            <Tab
              onClick={() => setCurrentTab("scenarios")}
              active={currentTab === "scenarios"}
            >
              Scenarios
            </Tab>
            {eventBus && (
              <Tab
                onClick={() => setCurrentTab("events")}
                active={currentTab === "events"}
              >
                Events
              </Tab>
            )}
          </Tabs>
          <Body>
            <CurrentTab />
          </Body>
        </Wrapper>
      </Overlay>
    </>
  );
};

export default DevPanel;
