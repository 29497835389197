import React from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  background-color: white;
  box-shadow: -10px 0px 15px rgba(31, 41, 55, 0.2);
  overflow: auto;
  height: calc(100%);
  width: 100%;
  @media (min-width: 900px) {
    width: 375px;
    border-radius: 0;
    ${({ center }) =>
      center &&
      css`
        border: 1px solid ${({ theme }) => theme.colors.border};
        border-radius: 16px;
        width: 100%;
      `}
  }
  @media (min-width: 1200px) {
    width: ${({ center }) => (center ? "100%" : "500px")};
  }
`;

const Panel = ({ center = false, children }) => {
  return <Wrapper center={center}>{children}</Wrapper>;
};

export default Panel;
