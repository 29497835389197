import React from "react";
import styled from "styled-components";
import ListHeader from "./ListHeader";

const PlaceholderWrapper = styled.div`
  margin-top: 16px;
`;

const Placeholder = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.light};
`;

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const LineGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const Line = styled.div`
  height: 8px;
  width: ${({ width }) => width};
  background-color: ${({ theme }) => theme.colors.light};
`;

const ListLoading = () => {
  return (
    <>
      <ListHeader disabled={true} />
      <PlaceholderWrapper>
        {Array.from(Array(4).keys()).map(v => (
          <Placeholder key={v}>
            <Circle />
            <Lines>
              <LineGroup>
                <Line width="80%" />
                <Line width="20%" />
              </LineGroup>
              <LineGroup>
                <Line width="100%" />
              </LineGroup>
            </Lines>
          </Placeholder>
        ))}
      </PlaceholderWrapper>
    </>
  );
};

export default ListLoading;
