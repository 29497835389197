import React, { useState } from "react";
import styled from "styled-components";
import useWiremock from "./wiremock/useWiremock";
import { Button } from "./Elements";

const Scenarios = styled.div`
  margin: 10px 0;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScenarioWrapper = styled.div`
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid gray;
  padding: 5px 0;
  align-items: center;
`;

const ScenarioName = styled.div`
  font-family: "Proxima Nova Semi Bold";
  font-size: 18px;
`;

const ScenarioDescription = styled.div`
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const DescriptionPopup = styled.div`
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid gray;
  background: white;
  box-shadow: -10px 0px 15px rgba(31, 41, 55, 0.2);
`;

const Scenario = ({ scenario, isActive, onActivate }) => {
  const description = scenario.mappings[0].metadata?.comment;
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  return (
    <ScenarioWrapper id={scenario.id}>
      {descriptionOpen && (
        <DescriptionPopup onClick={() => setDescriptionOpen(false)}>
          {description}
        </DescriptionPopup>
      )}
      <ScenarioName>Scenario: {scenario.name}</ScenarioName>
      <ScenarioDescription onClick={() => setDescriptionOpen(!descriptionOpen)}>
        {description}
      </ScenarioDescription>
      {isActive ? (
        <div>In Progress State: {scenario.state}</div>
      ) : (
        <Button onClick={onActivate}>Activate</Button>
      )}
    </ScenarioWrapper>
  );
};

const ScenariosTab = ({ baseURI }) => {
  const { scenarios, activateScenario, resetScenarios, INITIAL_STATE } =
    useWiremock(baseURI);

  return (
    <>
      <Actions>
        <Button onClick={resetScenarios}>Reset All Scenarios</Button>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload Application
        </Button>
      </Actions>
      <Scenarios>
        {scenarios.map(scenario => {
          return (
            <Scenario
              key={scenario.id}
              scenario={scenario}
              onActivate={() => activateScenario(scenario.id)}
              isActive={scenario.state !== INITIAL_STATE}
            />
          );
        })}
      </Scenarios>
    </>
  );
};

export default ScenariosTab;
