import styled from "styled-components";

const Button = styled.button`
  font-family: "Proxima Nova Semi Bold";
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 5px;
  background: purple;
  color: white;
  cursor: pointer;
`;

export { Button };
