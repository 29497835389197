import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "./Elements";

const Wrapper = styled.div`
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EventName = styled.div`
  font-family: "Proxima Nova Semi Bold";
  font-size: 18px;
`;

const EventTime = styled.div`
  font-size: 18px;
`;

const Payload = styled.div`
  position: relative;
  overflow: hidden;
  font-size: 18px;
  box-sizing: border-box;
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Popup = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid gray;
  background: white;
  box-shadow: -10px 0px 15px rgba(31, 41, 55, 0.2);
  z-index: 9;
`;

const Event = ({ ev }) => {
  const [payloadOpen, setPayloadOpen] = useState(false);
  const payload = ev.data && JSON.stringify(ev.data, null, 2);
  return (
    <Wrapper>
      {payloadOpen && (
        <Popup onClick={() => setPayloadOpen(false)}>{payload}</Popup>
      )}
      <EventName>{`Event Name: ${ev.name}`}</EventName>
      <Payload onClick={() => setPayloadOpen(true)}>{payload}</Payload>
      <EventTime>{`Time: ${ev.timestamp.toLocaleTimeString()}`}</EventTime>
    </Wrapper>
  );
};

const EmitEvent = styled.div`
  margin: 10px 0;
`;

const EventHistory = styled.div`
  margin: 10px 0;
`;
const EmitInput = styled.input`
  font-size: 24px;
  padding: 5px 10px;
`;

const EmitButton = styled(Button)`
  margin-left: 10px;
`;

const EventsTab = ({ events, eventBus }) => {
  const [newEvent, setNewEvent] = useState("");

  const handleEmit = () => {
    eventBus.emit(newEvent);
  };
  return (
    <div>
      <form action="#">
        <EmitEvent>
          <EmitInput
            value={newEvent}
            onChange={e => setNewEvent(e.target.value)}
          />
          <EmitButton onClick={handleEmit}>Emit Event</EmitButton>
        </EmitEvent>
      </form>
      <EventHistory>
        {events.length === 0 && "No Events"}
        {events.map(ev => {
          return <Event key={ev.timestamp.getTime()} ev={ev} />;
        })}
      </EventHistory>
    </div>
  );
};

export default EventsTab;
