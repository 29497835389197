import { rawTimeZones } from "@vvo/tzdb";
import { FILTER_RECENT, FILTER_OLDER } from "./consts";

export const getTimezoneAbbreviation = tz => {
  const value = rawTimeZones.find(timeZone => {
    return timeZone.name === tz || timeZone.group.includes(tz);
  });

  return value ? value.abbreviation : "";
};

export const formatTimeRange = (start, stop, timezone, locale) => {
  const from = start.toLocaleTimeString(locale, {
    timeStyle: "short",
    timeZone: timezone,
  });

  const to = stop.toLocaleTimeString(locale, {
    timeStyle: "short",
    timeZone: timezone,
  });

  const tz = getTimezoneAbbreviation(timezone);
  return `${from} - ${to} ${tz}`;
};

export const formatTime = (date, timezone, locale) => {
  const t = date.toLocaleTimeString(locale, {
    timeStyle: "short",
    timeZone: timezone,
  });

  const tz = getTimezoneAbbreviation(timezone);
  return `${t} ${tz}`;
};

export const filterRequests = (requests, filter) => {
  if (!requests?.length) {
    return [];
  }
  // 90 days ago
  const ts = new Date() - 1000 * 60 * 60 * 24 * 90;
  switch (filter) {
    case FILTER_RECENT:
      return requests.filter(r => r.createdAt * 1000 >= ts);
    case FILTER_OLDER:
      return requests.filter(r => r.createdAt * 1000 < ts);
    default:
      return [];
  }
};

const secondsPerMinute = 60;
const secondsPerHour = 60 * secondsPerMinute;
const secondsPerDay = 24 * secondsPerHour;
const secondsPerMonth = 30 * secondsPerDay;
const secondsPerYear = 12 * secondsPerMonth;

const timeAgoUnits = [
  { unit: "year", seconds: secondsPerYear },
  { unit: "month", seconds: secondsPerMonth },
  { unit: "day", seconds: secondsPerDay },
  { unit: "hour", seconds: secondsPerHour },
  { unit: "minute", seconds: secondsPerMinute },
];

const getTimeAgoUnitAndValue = secondsElapsed => {
  for (const { unit, seconds } of timeAgoUnits) {
    if (secondsElapsed >= seconds || unit === "minute") {
      let value = Math.floor(secondsElapsed / seconds) * -1;
      if (value === 0) {
        value = -1;
      }
      return { value, unit };
    }
  }
};

export const getTimeAgo = (d, lang) => {
  const rtf = new Intl.RelativeTimeFormat(lang, {
    localeMatcher: "best fit",
    numeric: "always",
    style: "long",
  });

  const now = new Date();
  const secondsElapsed = Math.floor((now.getTime() - d.getTime()) / 1000);
  const { value, unit } = getTimeAgoUnitAndValue(secondsElapsed);
  return rtf.format(value, unit);
};
