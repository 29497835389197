import { useState, useEffect } from "react";

const INITIAL_STATE = "Started";
const ACTIVATED_STATE = "Activated";

const useWiremock = baseURL => {
  const [scenarios, setScenarios] = useState([]);
  const [updates, setUpdates] = useState(0);

  useEffect(() => {
    const getScenarios = async () => {
      const response = await fetch(`${baseURL}/__admin/scenarios`, {
        method: "GET",
      });
      const body = await response.json();

      setScenarios(body.scenarios);
    };
    getScenarios();
  }, [baseURL, updates]);

  const getScenarioById = id => {
    return scenarios.find(scenario => scenario.id === id);
  };

  const resetScenarios = () => {
    fetch(`${baseURL}/__admin/scenarios/reset`, { method: "POST", headers: {} })
      .then(response => setUpdates(updates + 1))
      .catch(e => console.error(e));
  };

  const activateScenario = id => {
    const scenario = getScenarioById(id);

    const activateMapping = scenario.mappings.find(
      ({ requiredScenarioState, newScenarioState }) =>
        requiredScenarioState === INITIAL_STATE &&
        newScenarioState === ACTIVATED_STATE,
    );

    fetch(`${baseURL}${activateMapping.request.url}`, {
      method: "POST",
      headers: {},
    })
      .then(response => setUpdates(updates + 1))
      .catch(e => console.error(e));
  };

  return {
    INITIAL_STATE,
    ACTIVATED_STATE,
    scenarios,
    activateScenario,
    resetScenarios,
  };
};

export default useWiremock;
