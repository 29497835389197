import React from "react";
import styled from "styled-components";
import ExclamationIcon from "../assets/ErrorExclamation";

const ERROR_TYPES = {
  EXCLAMATION_CIRCLE: "exclamation-circle",
};

const IconComponents = {
  [ERROR_TYPES.EXCLAMATION_CIRCLE]: ExclamationIcon,
};

const Wrapper = styled.div`
  margin: 0 10px 24px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  box-shadow: -10px 0px 15px rgba(31, 41, 55, 0.2);
  font-family: ${({ theme }) => theme.fonts.semiBold};
  @media (min-width: 900px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
const MessageWrapper = styled.div`
  margin-left: 16px;
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const ErrorMessage = ({ message, type = ERROR_TYPES.EXCLAMATION_CIRCLE }) => {
  if (!message) {
    return null;
  }
  const IconComponent = IconComponents[type];
  return (
    <Wrapper>
      <IconComponent />
      <MessageWrapper>{message}</MessageWrapper>
    </Wrapper>
  );
};

export default ErrorMessage;
export { ERROR_TYPES };
