import styled from "styled-components";

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url("${({ url }) => url}");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: 900px) {
    background-size: cover;
    background-position: center center;
  }
`;

export default BackgroundImage;
