import React from "react";
import styled, { withTheme } from "styled-components";
import { Loading } from "../assets";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1;
`;

const RequestLoading = ({ loadingText, theme }) => {
  const {
    colors: { default: defaultColor, borderDark },
  } = theme;

  return (
    <Wrapper>
      <Loading outerColor={borderDark} innerColor={defaultColor} />
      {loadingText}
    </Wrapper>
  );
};

export default withTheme(RequestLoading);
