import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { swapCopyVariables } from "../utils";
import { formatTimeRange, getTimeAgo } from "./utils";
import { ArrowFull, Overflow } from "../assets";
import { Photo, Link } from "../elements";
import ConfirmBox from "./ConfirmBox";
import StatusBadge from "./StatusBadge";
import DetailView from "./DetailView";
import Initials from "./Initials";
import { STATUS_NEW, STATUSES_ACTIVE } from "./consts";
import { useOnClickOutside } from "../hooks";

const ProviderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 16px;

  @media (min-width: 900px) {
    flex-direction: row;
    gap: 7px;
    padding: 24px 16px;
  }
`;

const PhotoWrapper = styled.div`
  flex: 0;
`;

const StyledPhoto = styled(Photo)`
  width: 44px;
  height: 44px;
  @media (min-width: 900px) {
    width: 56px;
    height: 56px;
  }
`;

const NameWrapper = styled.div`
  overflow: hidden;
  flex: 1;
  text-align: center;
  margin-top: 6px;
  @media (min-width: 900px) {
    text-align: left;
    margin: 0;
  }
`;

const Name = styled.p`
  margin: 0;
  padding: 0 0 8px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DateTime = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.gray};
  line-height: 0.85;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Details = styled.span`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
`;

const StatusWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px;
  gap: 8px;
  align-items: center;
`;

const StatusBadgeWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  flex: 0 0 auto;
  justify-content: center;
`;

const StatusDescription = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.default};
`;

const Cancel = styled.span`
  display: block;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-size: ${({ theme }) => theme.fontSize.normal};
  flex: 0 0 auto;
  justify-self: flex-end;
`;

const CancelGroup = styled.div`
  position: relative;
  overflow: visible;
`;

const ConfirmCancel = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
`;

const Button = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.light};
  svg {
    flex: 1 0 auto;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 16px;

  @media (min-width: 900px) {
    display: none;
  }
`;

const OverflowButton = styled(Button)`
  position: absolute;
  top: 20px;
  left: 16px;

  @media (min-width: 900px) {
    position: initial;
    top: initial;
    left: initial;
  }
`;

const OverflowMenu = styled.div`
  min-width: 256px;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.1);
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: white;
  padding: 8px;
  position: absolute;
  top: 60px;
  left: 16px;
  z-index: 1;

  @media (min-width: 900px) {
    top: 80px;
    left: auto;
    right: 16px;
  }
`;

const OverflowItem = styled.button`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  padding: 8px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
  appearance: none;
  background-color: white;
  border: none;
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

const ConfirmBlock = styled.div`
  position: absolute;
  top: 60px;
  left: 16px;
  z-index: 10;
  @media (min-width: 900px) {
    top: 80px;
    left: auto;
    right: 16px;
  }
`;

const TimeAgoStatusDescription = ({ from, text, languageCode }) => {
  const [timeAgo, setTimeAgo] = useState(getTimeAgo(from, languageCode));

  useEffect(() => {
    const t = setInterval(() => {
      setTimeAgo(getTimeAgo(from, languageCode));
    }, 60 * 1000); // 1 minute

    return () => {
      clearInterval(t);
    };
  }, [from, languageCode]);

  return (
    <StatusDescription>
      {swapCopyVariables(text, {
        TIME_AGO: timeAgo,
      })}
    </StatusDescription>
  );
};

const RequestHeader = ({
  request,
  onCancelConfirm,
  cancelLoading,
  onToggleClientBlock,
  blockLoading,
  onClose,
  decryptMessage,
  maxHeight,
  locale,
  languageCode,
  isTherapist,
  showWorkflowLinks,
  confirmCancelTitleText,
  confirmCancelMessageText,
  confirmCancelNoText,
  confirmCancelYesText,
  detailsText,
  cancelText,
  statusText,
  statusDescriptionText,
  requestedTimeText,
  commentsText,
  emailText,
  phoneText,
  paymentText,
  issuesText,
  noneSpecifiedText,
  cashText,
  insuranceText,
  outOfNetworkText,
  viewDetailsText,
  unblockText,
  blockText,
  confirmUnblockTitleText,
  confirmBlockTitleText,
  confirmBlockText,
  confirmUnblockText,
  confirmBlockNoText,
  confirmBlockYesText,
  therapistNewStatusText,
  scheduleButtonText,
  declineButtonText,
  onSchedule,
  onDecline,
}) => {
  const [showOverflow, setShowOverflow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [confirmBlock, setConfirmBlock] = useState(false);
  const overflowRef = useRef(null);
  useOnClickOutside(overflowRef, () => setShowOverflow(false));

  const getDateTime = () => {
    const date = request.startDate.toLocaleDateString(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    const time = formatTimeRange(
      request.startDate,
      request.stopDate,
      request.timezone,
      locale,
    );

    return `${date}, ${time}`;
  };

  const getStatusDescription = () => {
    if (isTherapist && request.status === STATUS_NEW) {
      const from = new Date(request.createdAt * 1000);

      return (
        <TimeAgoStatusDescription
          from={from}
          text={therapistNewStatusText}
          languageCode={languageCode}
        />
      );
    }

    return statusDescriptionText;
  };

  if (!request) {
    return (
      <ProviderWrapper>
        <CloseButton onClick={onClose}>
          <ArrowFull direction="down" />
        </CloseButton>
      </ProviderWrapper>
    );
  }

  return (
    <div>
      <ProviderWrapper>
        <PhotoWrapper>
          {isTherapist ? (
            <Initials name={request.clientName} />
          ) : (
            <StyledPhoto
              src={request.profilePhotoUrl}
              alt={request.profileName}
            />
          )}
        </PhotoWrapper>
        <NameWrapper>
          <Name>{isTherapist ? request.clientName : request.profileName}</Name>
          <DateTime>
            {getDateTime()} -{" "}
            <Details onClick={() => setShowDetails(!showDetails)}>
              {detailsText}
            </Details>
          </DateTime>
          {showDetails && (
            <DetailView
              request={request}
              onClose={() => setShowDetails(false)}
              decryptMessage={decryptMessage}
              maxHeight={maxHeight}
              detailsText={detailsText}
              requestedTimeText={requestedTimeText}
              commentsText={commentsText}
              emailText={emailText}
              phoneText={phoneText}
              paymentText={paymentText}
              issuesText={issuesText}
              noneSpecifiedText={noneSpecifiedText}
              cashText={cashText}
              insuranceText={insuranceText}
              outOfNetworkText={outOfNetworkText}
            />
          )}
        </NameWrapper>
        {isTherapist && (
          <>
            <OverflowButton onClick={() => setShowOverflow(!showOverflow)}>
              <Overflow />
            </OverflowButton>
            {showOverflow && (
              <OverflowMenu
                ref={overflowRef}
                onClick={() => setShowOverflow(false)}
              >
                <OverflowItem onClick={() => setShowDetails(true)}>
                  {viewDetailsText}
                </OverflowItem>
                <OverflowItem onClick={() => setConfirmBlock(true)}>
                  {request.blocked ? unblockText : blockText}
                </OverflowItem>
              </OverflowMenu>
            )}
            {confirmBlock && (
              <ConfirmBlock>
                <ConfirmBox
                  title={
                    request.blocked
                      ? confirmUnblockTitleText
                      : confirmBlockTitleText
                  }
                  text={
                    request.blocked
                      ? swapCopyVariables(confirmUnblockText, {
                          NAME: request.clientName,
                        })
                      : swapCopyVariables(confirmBlockText, {
                          NAME: request.clientName,
                        })
                  }
                  closeText={confirmBlockNoText}
                  confirmText={confirmBlockYesText}
                  onClose={() => setConfirmBlock(false)}
                  onConfirm={() => {
                    onToggleClientBlock(!request.blocked);
                    setConfirmBlock(false);
                  }}
                  loading={blockLoading}
                  disabled={blockLoading}
                />
              </ConfirmBlock>
            )}
          </>
        )}
        <CloseButton onClick={onClose}>
          <ArrowFull direction="down" />
        </CloseButton>
      </ProviderWrapper>
      <StatusWrapper>
        <StatusBadgeWrapper>
          <StatusBadge status={request.status} statusText={statusText} />
        </StatusBadgeWrapper>
        <StatusDescription>
          {getStatusDescription()}
          {showWorkflowLinks && (
            <div>
              <Link onClick={onSchedule}> {scheduleButtonText}</Link> |{" "}
              <Link onClick={onDecline}>{declineButtonText}</Link>
            </div>
          )}
        </StatusDescription>
        {!isTherapist && STATUSES_ACTIVE.indexOf(request.status) >= 0 && (
          <CancelGroup>
            <Cancel onClick={() => setConfirmCancel(!confirmCancel)}>
              {cancelText}
            </Cancel>
            {confirmCancel && (
              <ConfirmCancel>
                <ConfirmBox
                  title={confirmCancelTitleText}
                  text={confirmCancelMessageText}
                  closeText={confirmCancelNoText}
                  confirmText={confirmCancelYesText}
                  onClose={() => setConfirmCancel(!confirmCancel)}
                  onConfirm={onCancelConfirm}
                  loading={cancelLoading}
                  disabled={cancelLoading}
                />
              </ConfirmCancel>
            )}
          </CancelGroup>
        )}
      </StatusWrapper>
    </div>
  );
};

export default RequestHeader;
