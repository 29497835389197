import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import MessagePreview from "./MessagePreview";
import { swapCopyVariables } from "../utils";
import { useWindowSize } from "../hooks";
import { STATUS_NEW, DESKTOP_BREAKPOINT, FILTER_RECENT } from "./consts";
import ListHeader from "./ListHeader";
import StatusBadge from "./StatusBadge";
import { filterRequests } from "./utils";

const RequestList = styled.div`
  margin-top: 16px;
`;

const RequestItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px;
  gap: 7px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.light};
    `}

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

const BadgeWrapper = styled.div`
  width: 19px;
  align-self: flex-start;
`;

const Badge = styled.div`
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red};
  width: 10px;
  height: 10px;
  margin-top: 4px;
`;

const DetailsWrapper = styled.div`
  overflow: hidden;
  flex: 1;
`;

const Name = styled.p`
  margin: 0;
  padding: 0 0 5px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Updated = styled.p`
  margin: 0;
  padding: 0 0 5px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.default};
  line-height: 0.85;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusWrapper = styled.div`
  flex: 0;
`;

const None = styled.p`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
`;

const List = ({
  isTherapist,
  locale,
  requestHistory,
  selectedRequestId,
  requestFilter,
  onSelect,
  onFilterChange,
  // copy:
  titleText,
  recentText,
  olderText,
  noRecentText,
  noOlderText,
  updatedText,
  newLabelText,
  decryptMessage,
  messageLoadingText,
}) => {
  const requestHistoryFiltered = filterRequests(requestHistory, requestFilter);
  const hasUnread =
    requestHistoryFiltered.filter(r =>
      isTherapist ? r.newActivityForTherapist : r.newActivityForClient,
    ).length > 0;

  const getUpdatedAt = timestamp => {
    const d = new Date(timestamp * 1000);
    return d.toLocaleDateString(locale, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  // Auto-select first request on desktop. Assumes request history is sorted.
  const { width } = useWindowSize();
  useEffect(() => {
    const isDesktop = width >= DESKTOP_BREAKPOINT;
    if (isDesktop && requestHistory?.length && !selectedRequestId) {
      const request = requestHistory[0];
      onSelect(request.id);
    }
  }, [width, requestHistory, onSelect, selectedRequestId]);

  return (
    <>
      <ListHeader
        requestFilter={requestFilter}
        onFilterChange={onFilterChange}
        titleText={titleText}
        recentText={recentText}
        olderText={olderText}
      />
      <RequestList>
        {requestHistoryFiltered.length === 0 && (
          <None>
            {requestFilter === FILTER_RECENT ? noRecentText : noOlderText}
          </None>
        )}
        {requestHistoryFiltered.map((r, i) => (
          <RequestItem
            key={r.id}
            active={r.id === selectedRequestId}
            onClick={() => onSelect(r.id)}
          >
            {hasUnread && (
              <BadgeWrapper>
                {((!isTherapist && r.newActivityForClient) ||
                  (isTherapist && r.newActivityForTherapist)) && <Badge />}
              </BadgeWrapper>
            )}
            <DetailsWrapper>
              <Name>{isTherapist ? r.clientName : r.profileName}</Name>
              <Updated>
                {swapCopyVariables(updatedText, {
                  DATE: getUpdatedAt(r.lastActivityTimestamp),
                })}
              </Updated>
              {r.message && (
                <MessagePreview
                  decryptMessage={decryptMessage}
                  encrypted={r.message}
                  loadingText={messageLoadingText}
                  id={r.id}
                />
              )}
            </DetailsWrapper>
            {isTherapist && r.status === STATUS_NEW && (
              <StatusWrapper>
                <StatusBadge status={r.status} statusText={newLabelText} />
              </StatusWrapper>
            )}
          </RequestItem>
        ))}
      </RequestList>
    </>
  );
};

export default List;
