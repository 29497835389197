import React from "react";
import styled from "styled-components";
import { getInitials } from "../utils";

const Wrapper = styled.div`
  display: flex;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smallXl};
  line-height: 1;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.primaryBlue};
`;

const Initials = ({ name, backgroundColor }) => {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      {name ? getInitials(name) : ""}
    </Wrapper>
  );
};

export default Initials;
