import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Checkbox from "../../assets/Checkbox";

const Wrapper = styled.div`
  border-radius: 10px;
  padding: 10px 15px;
  background: ${({ theme }) => theme.colors.light};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const RequirementWrapper = styled.div`
  margin: 10px 0;
  svg {
    margin-right: 10px;
    vertical-align: middle;
  }
`;

const Requirement = ({ children, active }) => {
  return (
    <RequirementWrapper>
      <Checkbox active={active} />
      <span>{children}</span>
    </RequirementWrapper>
  );
};

const PasswordStrength = ({
  password = "",
  length = 8,
  uppercase = true,
  number = true,
  setValid,
  lengthText,
  uppercaseText,
  numberText,
}) => {
  const hasLength = password.length >= length;
  const hasNumber = /\d/.test(password);
  const hasUpper = /[A-Z]/.test(password);
  useEffect(() => {
    setValid(hasLength && hasNumber && hasUpper);
  }, [setValid, hasLength, hasNumber, hasUpper, password]);

  return (
    <Wrapper>
      <Requirement active={hasLength}>{lengthText}</Requirement>
      {uppercase && (
        <Requirement active={hasUpper}>{uppercaseText}</Requirement>
      )}
      {number && <Requirement active={hasNumber}>{numberText}</Requirement>}
    </Wrapper>
  );
};

export default PasswordStrength;
