import React, { useState, useCallback, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import ReactTooltip from "react-tooltip";
import { TextArea } from "../elements";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const TooltipWrapper = styled(ReactTooltip)`
  max-width: 200px;
`;

const InputWrapper = styled.form`
  position: relative;
  width: 100%;
`;

const Input = styled(TextArea)`
  border: none;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  padding: 20px;
  padding-right: 40px;
  height: auto;
  min-height: auto;
  line-height: 20px;
  font-size: 16px;
  resize: none;
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
    ${({ disabled }) =>
      disabled
        ? css`
            opacity: 0.5;
          `
        : css`
            opacity: 1;
          `}
  }
`;

const inactiveSendIconData =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMDA5OTk5OTkgMThMMjEgOUwwLjAwOTk5OTk5IDBMMCA3TDE1IDlMMCAxMUwwLjAwOTk5OTk5IDE4WiIgZmlsbD0iI2RkZGRkZCIvPgo8L3N2Zz4K";

const sendIconData =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMDA5OTk5OTkgMThMMjEgOUwwLjAwOTk5OTk5IDBMMCA3TDE1IDlMMCAxMUwwLjAwOTk5OTk5IDE4WiIgZmlsbD0iIzAwODRGRiIvPgo8L3N2Zz4K";

const SendButton = styled.button`
  position: absolute;
  width: 21px;
  height: 18px;
  right: 10px;
  bottom: 20px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  appearance: none;
  border: 0;
  outline: none;
  background-color: transparent;
  ${({ disabled }) =>
    disabled
      ? css`
          background-image: url("${inactiveSendIconData}");
        `
      : css`
          cursor: pointer;
          background-image: url("${sendIconData}");
        `}
`;

export default function ChatFooter({
  placeholder,
  disabled,
  disabledTooltip,
  onSend,
}) {
  const [message, setMessage] = useState("");
  const footerRef = useRef(null);
  const inputRef = useRef(null);

  const sendDisabled = disabled || message.trim() === "";

  const handleInput = e => {
    const input = e.target;
    // restrict message to 500 characters:
    let value = input.value;
    if (value.length > 1000) {
      value = value.substring(0, 1000);
    }
    setMessage(value);

    // set textarea height:
    input.style.height = "auto";
    if (input.scrollHeight > input.offsetHeight) {
      input.style.height = input.scrollHeight + "px";
    }
  };

  const handleSend = useCallback(() => {
    if (sendDisabled) {
      return;
    }
    onSend(message);
    setMessage("");
    const input = inputRef.current;
    if (input) {
      input.style.height = "auto";
      input.focus();
    }
  }, [message, onSend, sendDisabled]);

  const handleKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  // Prevent scrolling body when touchmove fired on chat footer.
  // Addresses issue where iOS does not respect `overflow: hidden`
  // when virtual keyboard is open and allows scrolling.
  useEffect(() => {
    const footer = footerRef.current;
    if (!footer) {
      return;
    }
    const preventScroll = e => {
      e.preventDefault();
    };

    footer.addEventListener("touchmove", preventScroll, { passive: false });
    return () => {
      footer.removeEventListener("touchmove", preventScroll, {
        passive: false,
      });
    };
  }, []);

  return (
    <Wrapper ref={footerRef}>
      <InputWrapper
        data-tip={disabled && disabledTooltip}
        onSubmit={e => {
          e.preventDefault();
          handleSend();
        }}
      >
        <Input
          rows="1"
          placeholder={placeholder}
          value={message}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          disabled={disabled}
        />
        <SendButton
          type="submit"
          disabled={sendDisabled}
          id="sendMessageToClient"
        />
      </InputWrapper>
      {disabled && <TooltipWrapper />}
    </Wrapper>
  );
}
