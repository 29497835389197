import React from "react";
import styled from "styled-components";
import { Button } from "../elements";
import parsePhoneNumber from "libphonenumber-js";

const ReferralArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme, isChat }) =>
    isChat ? "white" : theme.colors.light};
  color: ${({ theme }) => theme.colors.default};
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 15px;
  gap: 15px;
`;

const ReferralInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9999;
  justify-content: center;
`;

const ReferralTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const ReferralText = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const ReferralAction = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const EmailBtn = styled(Button)`
  min-width: 80px;
  width: 100%;
  height: 32px;
  padding: 8px 16px;
`;

const Referral = ({ countryCode, referral, btnText, isChat = false }) => {
  const handleClick = () => {
    window.location.href = `mailto:${referral.email}`;
  };

  const country = countryCode ? countryCode.toUpperCase() : "US";
  const formattedPhone = parsePhoneNumber(
    referral.phone,
    country,
  )?.formatNational();

  let title = referral.name;
  let text1 = referral.email;
  let text2 = formattedPhone || referral.phone;

  if (!title) {
    if (text1) {
      title = text1;
      text1 = null;
    } else {
      title = text2;
      text2 = null;
    }
  }

  return (
    <ReferralArea isChat={isChat}>
      <ReferralInfo>
        <ReferralTitle>{title}</ReferralTitle>
        {text1 && <ReferralText>{text1}</ReferralText>}
        {text2 && <ReferralText>{text2}</ReferralText>}
      </ReferralInfo>
      {referral.email && (
        <ReferralAction>
          <EmailBtn onClick={handleClick}>{btnText}</EmailBtn>
        </ReferralAction>
      )}
    </ReferralArea>
  );
};

export default Referral;
