import React from "react";
import styled, { css } from "styled-components";
import { H2 } from "../elements";
import { FILTER_RECENT, FILTER_OLDER } from "./consts";

const Header = styled(H2)`
  margin-top: 16px;
`;

const StatusToggleWrapper = styled.div`
  display: flex;
  gap: 4px;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 8px;
  margin-top: 24px;
`;

const StatusToggleItem = styled.button`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.default};
  padding: 4px;
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: transparent;

  ${({ active }) =>
    active &&
    css`
      background-color: white;
      font-family: ${({ theme }) => theme.fonts.semiBold};
      background-color: white;
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
      border-radius: 4px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${({ theme }) => theme.colors.transparentDefaultText};
    `}
`;

const ListHeader = ({
  disabled,
  requestFilter,
  onFilterChange,
  titleText,
  recentText,
  olderText,
}) => {
  return (
    <>
      <Header>{titleText}</Header>
      <StatusToggleWrapper>
        <StatusToggleItem
          active={requestFilter === FILTER_RECENT}
          type="button"
          disabled={disabled}
          onClick={() => onFilterChange(FILTER_RECENT)}
        >
          {recentText}
        </StatusToggleItem>
        <StatusToggleItem
          active={requestFilter === FILTER_OLDER}
          type="button"
          disabled={disabled}
          onClick={() => onFilterChange(FILTER_OLDER)}
        >
          {olderText}
        </StatusToggleItem>
      </StatusToggleWrapper>
    </>
  );
};

export default ListHeader;
