import React from "react";
import styled from "styled-components";
import AutoLinkText from "react-autolink-text2";

const Bubble = styled.div`
  background: ${({ isSender, theme }) =>
    isSender ? theme.colors.chat.sent : theme.colors.chat.received};
  color: ${({ isSender, theme }) =>
    isSender ? "white" : theme.colors.default};
  padding: 12px 20px;
  border-radius: ${({ isSender }) =>
    isSender ? "30px 30px 0 30px" : "30px 30px 30px 0"};

  margin: ${({ isSender }) =>
    isSender ? "15px 0 15px 30px" : "15px 50px 35px 0"};

  @media (min-width: 900px) {
    margin: ${({ isSender }) =>
      isSender ? "15px 0 15px 50px" : "15px 50px 15px 0"};
  }

  word-break: break-word;
  a {
    overflow-wrap: break-word;
    text-decoration: none;
    color: ${({ isSender, theme }) =>
      isSender ? "white" : theme.colors.default};
    &:visited {
      color: ${({ isSender, theme }) =>
        isSender ? "white" : theme.colors.default};
      text-decoration: none;
    }
  }
`;

const ChatBubble = ({ isSender, message, children }) => {
  const messageParts = message.split("\n");

  return (
    <div>
      <Bubble isSender={isSender}>
        {messageParts.map((part, i) => (
          <React.Fragment key={i}>
            <AutoLinkText text={part} linkProps={{ target: "_blank" }} />
            {i < messageParts.length && <br />}
          </React.Fragment>
        ))}
        {children}
      </Bubble>
    </div>
  );
};

export default ChatBubble;
