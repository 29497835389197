import React, { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import parsePhoneNumber from "libphonenumber-js";
import { Loading, CloseX } from "../assets";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { formatTimeRange } from "./utils";

const Wrapper = styled.div`
  position: absolute;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.boxShadow};
  border-radius: 10px;
  padding: 24px;
  width: calc(100% - 88px);
  max-width: 400px;
  max-height: ${({ maxHeight }) => maxHeight - 158}px;
  overflow-y: auto;
  overflow-wrap: break-word;
  z-index: 20;
  top: 110px;
  left: 0;
  right: 0;
  margin: auto;

  @media (min-width: 900px) {
    left: 20px;
    top: 80px;
  }

  p {
    margin: 0;
  }
`;

const CloseButton = styled.div`
  float: right;
  cursor: pointer;
`;

const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.smallXl};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  margin: 0;
`;

const Label = styled.h4`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  margin: 0 0 10px;
`;

const Section = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  &:last-child {
    border-bottom: none;
  }
`;

const getDateTime = (request, locale) => {
  const date = request.startDate.toLocaleDateString(locale, {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const time = formatTimeRange(
    request.startDate,
    request.stopDate,
    request.timezone,
    locale,
  );

  return `${date}, ${time}`;
};

const DetailView = ({
  request,
  onClose,
  decryptMessage,
  maxHeight,
  locale,
  // copy:
  detailsText,
  requestedTimeText,
  commentsText,
  emailText,
  phoneText,
  paymentText,
  issuesText,
  noneSpecifiedText,
  cashText,
  insuranceText,
  outOfNetworkText,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, onClose);
  const [message, setMessage] = useState(null);
  const decrypt = useCallback(
    async m => {
      const decrypted = await decryptMessage(m);
      setMessage(decrypted);
    },
    [decryptMessage],
  );

  useEffect(() => {
    decrypt(request.message);
  }, [request, decrypt]);

  const issues = request.issues.map(issue => issue.title);
  if (request.otherIssue !== "") {
    issues.push(request.otherIssue);
  }
  const insurances = request.insurances.map(insurance => insurance.title);
  if (request.otherInsurance !== "") {
    insurances.push(request.otherInsurance);
  }
  if (request.outOfNetwork) {
    insurances.push(outOfNetworkText);
  }
  const phoneNumber = parsePhoneNumber(request.clientPhoneNumber);

  return (
    <Wrapper maxHeight={maxHeight} ref={ref}>
      <CloseButton onClick={onClose}>
        <CloseX />
      </CloseButton>
      <Title>{detailsText}</Title>
      <Section>
        <Label>{requestedTimeText}</Label>
        <p>{getDateTime(request, locale)}</p>
      </Section>
      <Section>
        <Label>{commentsText}</Label>
        <p>{message == null ? <Loading /> : message}</p>
      </Section>
      <Section>
        <Label>{emailText}</Label>
        <p>{request.clientEmail}</p>
      </Section>
      <Section>
        <Label>{phoneText}</Label>
        <p>{phoneNumber.formatNational()}</p>
      </Section>
      <Section>
        <Label>{paymentText}</Label>
        <p>
          {request.paymentMethod === "cash"
            ? cashText
            : `${insuranceText} - ${insurances.join(", ")}`}
        </p>
      </Section>
      <Section>
        <Label>{issuesText}</Label>
        <p>{issues.length ? issues.join(", ") : noneSpecifiedText}</p>
      </Section>
    </Wrapper>
  );
};

export default DetailView;
