import React from "react";
import styled, { css } from "styled-components";
import { STATUS_NEW, STATUSES_ACTIVE } from "./consts";

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1.66;
  border-radius: 4px;
  padding: 0 4px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;

  ${({ theme, variant }) => {
    switch (variant) {
      case "active":
        return css`
          color: ${theme.colors.primaryBlue};
          background-color: ${theme.colors.lightBlue};
        `;

      case "new":
        return css`
          color: ${theme.colors.red};
          background-color: ${theme.colors.lightRed};
        `;

      default:
        return css`
          color: ${theme.colors.default};
          background-color: "rgba(44, 45, 48, 0.1)";
        `;
    }
  }}
`;

const StatusBadge = ({ status, statusText }) => {
  const isNew = status === STATUS_NEW;
  const isActive = STATUSES_ACTIVE.indexOf(status) >= 0;

  if (!(status && statusText)) {
    return null;
  }

  const variant = isNew ? "new" : isActive ? "active" : "";
  return <Wrapper variant={variant}>{statusText}</Wrapper>;
};

export default StatusBadge;
