import React, { Fragment, useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Loading } from "../assets";
import { useWindowSize } from "../hooks";
import { swapCopyVariables } from "../utils";
import ChatBubble from "./ChatBubble";
import ReferralBubble from "./ReferralBubble";

const Read = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.gray};
  text-align: right;
`;

const LoadingWrapper = styled.div`
  padding: 10px;
  text-align: center;
`;

const ScrollWrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
  padding: 0 20px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const MessageDate = styled.div`
  position: relative;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.gray};
  margin: 20px 0 0;
`;

const BubbleWrapper = styled.div`
  margin: 15px 0;
`;

const ChatLog = ({
  messages,
  hasMore,
  countryCode,
  locale,
  getNextPage,
  lastReadMessageID,
  lastReadDate,
  readText,
  referralBtnText,
  children,
}) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const chatRef = useRef(null);
  const scrollRef = useRef(null);
  const { height } = useWindowSize();
  const newestMessageId = messages[messages.length - 1]?.id;

  useEffect(() => {
    if (!chatRef.current || !scrollRef.current) {
      return;
    }
    scrollRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [height, newestMessageId]);

  useEffect(() => {
    const onScroll = async () => {
      if (scrollRef.current.scrollTop > 0 || !hasMore || loadingMore) {
        return;
      }
      const oldScrollHeight = scrollRef.current.scrollHeight;
      setLoadingMore(true);
      await getNextPage();
      setLoadingMore(false);
      const newScrollHeight = scrollRef.current.scrollHeight;
      const diff = newScrollHeight - oldScrollHeight + 40; // Add 20px for date heading
      scrollRef.current.scrollTo({
        top: diff,
      });
    };
    const r = scrollRef.current;

    r.addEventListener("scroll", onScroll);
    return () => r.removeEventListener("scroll", onScroll);
  }, [hasMore, getNextPage, loadingMore]);

  // Continue to load messages until scrollable
  useEffect(() => {
    const getNext = async () => {
      setLoadingMore(true);
      await getNextPage();
      setLoadingMore(false);
      scrollRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: "smooth",
      });
    };
    if (
      !loadingMore &&
      hasMore &&
      scrollRef.current.scrollHeight <= scrollRef.current.clientHeight
    ) {
      getNext();
    }
  }, [getNextPage, hasMore, loadingMore]);

  // Prevent touch devices from scrolling body after scrolling beyond container.
  // This addresses an iOS-specific issue.
  useEffect(() => {
    const chatLog = scrollRef.current;
    if (!chatLog) {
      return;
    }

    const handleTouchScroll = e => {
      const target = e.currentTarget;
      if (!target) {
        return;
      }

      const child = target.children[0];
      if (child.clientHeight < target.clientHeight) {
        e.preventDefault();
        return;
      }

      if (target.scrollTop === 0) {
        target.scrollTop = 1;
      } else if (
        target.scrollHeight ===
        target.scrollTop + target.offsetHeight
      ) {
        target.scrollTop -= 1;
      }
    };

    chatLog.addEventListener("touchmove", handleTouchScroll, {
      passive: false,
    });
    return () => {
      chatLog.removeEventListener("touchmove", handleTouchScroll, {
        passive: false,
      });
    };
  }, []);

  const days = messages.reduce((acc, m) => {
    const day = new Date(m.createdAt).toLocaleDateString(locale, {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    if (acc[day] == null) {
      acc[day] = [];
    }
    acc[day].push(m);
    return acc;
  }, {});

  const getBubble = m => {
    if (
      m.type === "RequestResponded" ||
      m.type === "RequestAccepted" ||
      (m.type === "RequestDeclined" && !m.referral) ||
      m.type === "MessageCreated"
    ) {
      return <ChatBubble isSender={m.self} message={m.message} />;
    }

    if (m.type === "RequestDeclined" && m.referral) {
      return (
        <ReferralBubble
          isSender={m.self}
          message={m.message}
          referral={m.referral}
          countryCode={countryCode}
          referralBtnText={referralBtnText}
        />
      );
    }

    return null;
  };

  return (
    <ScrollWrapper ref={scrollRef}>
      <Wrapper ref={chatRef}>
        {loadingMore && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        {children}
        {Object.keys(days).map(d => {
          const day = days[d];
          return (
            <Fragment key={d}>
              <MessageDate>{d}</MessageDate>
              {day.map(m => (
                <BubbleWrapper key={`${m.id}`}>
                  {getBubble(m)}
                  {lastReadMessageID === m.id && (
                    <Read>
                      {swapCopyVariables(readText, {
                        DATE: lastReadDate.toISOString().split("T")[0],
                      })}
                    </Read>
                  )}
                </BubbleWrapper>
              ))}
            </Fragment>
          );
        })}
      </Wrapper>
    </ScrollWrapper>
  );
};

export default ChatLog;
