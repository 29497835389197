import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1.66;
  border-radius: 4px;
  padding: 0 4px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.primaryBlue};
  background-color: ${({ theme }) => theme.colors.lightBlue};
`;

const TypeBadge = ({ typeText }) => {
  return <Wrapper>{typeText}</Wrapper>;
};

export default TypeBadge;
