import React from "react";
import Referral from "./Referral";
import ChatBubble from "./ChatBubble";

const ReferralBubble = ({
  isSender,
  message,
  referral,
  countryCode,
  referralBtnText,
}) => {
  return (
    <ChatBubble isSender={isSender} message={message}>
      <Referral
        countryCode={countryCode}
        referral={referral}
        btnText={referralBtnText}
        isChat={true}
      />
    </ChatBubble>
  );
};

export default ReferralBubble;
